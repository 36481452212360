@font-face {
  font-family: "MD System Web";
  src: url("/assets/fonts/md_system-2022.05.10-regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "MD System Web";
  src: url("/assets/fonts/md_system-2022.05.10-italic.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "MD System Web";
  src: url("/assets/fonts/md_system-2022.05.10-bold.woff2") format("woff2");
  font-weight: 700;
}
