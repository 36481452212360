@import "mdio";
@import "mdsystem";

:root {
  --ff-body: "MD System Web", system-ui, sans-serif;
  --ff-code: "MD IO Web", "SF Mono", monospace;

  --fs-h1: 1.52rem;
  --fs-h2: 1.33rem;
  --fs-h3: 1.15rem;

  --color-fg: #000000;
  --color-fg-alt: #282828;
  --color-bg: #ffffff;
  --color-bg-alt: #f0f0f0;

  --color-accent: #0031a9;
}

@media only screen and (prefers-color-scheme: dark) {
  :root {
    --color-fg: #ffffff;
    --color-fg-alt: #e0e6f0;
    --color-bg: #000000;
    --color-bg-alt: #191a1b;

    --color-accent: #80b2f0;
  }
}

html {
  font-size: 15px;

  background: var(--color-bg);
  color: var(--color-fg-alt);
}

@media only screen and (min-width: 512px) {
  html {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
}

body {
  font-family: var(--ff-body);

  line-height: 1.4;

  padding: 1rem;

  max-width: 40rem;
  margin: 1rem auto;
}

h1,
h2,
h3 {
  color: var(--color-fg);
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

p {
  margin: 1em 0;
}

a {
  color: var(--color-accent);
}

a:hover,
a:visited {
  background: var(--color-bg-alt);
  transition: 0.125s all;
}

ul {
  margin-left: 0.625rem;
  padding-left: 1.25rem;
}

li {
  margin-top: 0.25em;
}

pre {
  background: var(--color-bg-alt);
  padding: 0.75em;

  overflow-x: scroll;
}

code {
  font-family: var(--ff-code);
  font-size: 0.8rem;
}

p > code,
li > code {
  background: var(--color-bg-alt);
  padding: 0.125em 0.375em;
  font-size: 0.85em;

  border-radius: 0.125rem;
}

.header__name {
  font-size: var(--fs-h2);

  display: block;
  margin-bottom: 0.25em;
}

hr {
  border: none;
  border-top: 1px dashed var(--color-fg-alt);
  margin: 1rem 0;
}

.hr--top {
  margin-bottom: 2rem;
}

.hr--bottom {
  margin-top: 2.5rem;
}

small {
  font-size: 0.85rem;
}

footer {
  text-align: center;

  a {
    margin: 0 0.125em;
  }
}

.post-title {
  margin-bottom: 0.375rem;
}

.post-title + p {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

.header__links a {
  margin-right: 0.5em;
}
