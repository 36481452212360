@font-face {
  font-family: "MD IO Web";
  src: url("/assets/fonts/md_io-0.5-regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "MD IO Web";
  src: url("/assets/fonts/md_io-0.5-bold.woff2") format("woff2");
  font-weight: 700;
}
